export const apiConstants = {
    LOGIN_USER: '/owner/login',
    GET_ROLES: '/role/getAllRoles',
    SIGNUP: '/signup',
    USERLIST: '/users',
    CREATE_CUSTOMER: '/owner/createCustomer',
    GET_CUSTOMER_BY_ID: '/owner/getCustomerById',
    UPDATE_CUSTOMER_BY_ID: '/owner/updateCustomer',
    UPDATE_SUPERADMIN:'/user/updateUserStatus',
    SEARCH_CUSTOMERS: '/owner/search',
    FORGOT_PASSWORD: '/owner/forgetPasswordForOwner',
    RESET_PASSWORD: '/user/resetPassword',
    GET_SKILLS: '/owner/getskills',
    CREATE_SKILLS: '/owner/createSkill',
    CREATE_SUPER_ADMIN:'/user/createSuperAdmin',
    GET_QUESTIONBANK:'/owner/getQbank',
    CREATE_QUESTION_BANK: '/owner/createQbank',
    CREATE_QUESTION: '/owner/createQues',
    GET_QUESTIONS_BANK_ID: '/owner/getQuestionsByBankId',
    GET_QUESTION_BY_ID: '/owner/getQuestionsById',
    GET_SUPERADMIN_BYCID: '/owner/getSuperAdminByCId',
    DELETE_QUESTION: '/owner/deleteQuestionById',
    DELETE_QUESTION_BANK: '/owner/deleteQbank',
    DELETE_ROLE: '/role/deleteRole',
    DELETE_SKILL: '/owner/deleteSkill',
    UPDATE_QUESTION_BANK_BY_ID: '/owner/updateQuestionBankById',
    UPLOAD_PICTURE: '/utils/uploadPicture',
    CREATE_SUBADMIN: '/owner/createSubAdmin',
    GET_SUBADMINS:'/owner/getSubAdmin',
    DELETE_SUBADMIN: '/owner/deleteSubAdmin',
    CUSTOMER_INQUIRIES: '/owner/queriesSearch',
    CUSTOMER_INQUIRIES_DETAILS:  '/owner/getCustomerEnquiryById',
    DELETE_INQUIRY: '/owner/deleteEnquiryCustomers',
    GET_LANGUAGES: '/owner/getAllLanguages',
    UPLOAD_IMAGE_EDITOR: '/owner/uploadAssessmentImages',
    GET_NOTIFICATIONS: '/notification/getNotification',
    GET_ALL_NOTIFICATIONS: '/notification/getAllNotificationById',
    CLICK_TO_NOTIFICATION: '/notification/clickToNotification',
    READ_ALL_NOTIFICATION: '/notification/readAllNotification',
    UPDATE_QUESTION_BY_ID: '/owner/updateQuestion'
};
// V2 = user

//api/organization/adminList => api/user/adminList